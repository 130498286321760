
import { ISectionMedia } from '@/types'
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { TranslationHandler } from '@/mixins/translation-handler'

@Component({
})
export default class Media extends Mixins(TranslationHandler) {
  @Prop() item!: ISectionMedia

  borderRadius = {
    medium: 'rounded-3xl',
    none: '',
    full: 'rounded-3xl'
  }

  innerWidth = 0

  get url () {
    if (this.item.data.messages && this.item.data.messages[this.$tLocaleCurrent] && this.item.data.messages[this.$tLocaleCurrent].url) {
      return this.item.data.messages[this.$tLocaleCurrent].url
    }

    if (this.item.data.url) { // url is used for older campaigns, messages for newer
      return this.item.data.url
    }

    return ''
  }

  mounted () {
    this.setInnerWidth()
    window.addEventListener('resize', this.setInnerWidth)
  }

  destroyed () {
    window.removeEventListener('resize', this.setInnerWidth)
  }

  setInnerWidth () {
    this.innerWidth = window.innerWidth
  }

  get width () {
    return parseInt(this.item.data.width) < this.innerWidth ? this.item.data.width : '100%'
  }
}

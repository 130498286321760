
import { ISectionCard } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import { TranslationHandler } from '../../mixins/translation-handler'

@Component({})
export default class Card extends Mixins(TranslationHandler) {
  @Prop() item!: ISectionCard;

  get translations (): ISectionCard['data']['messages'][0] {
    return {
      title: this.$tx(this.item.data.messages, 'title'),
      subtitle: this.$tx(this.item.data.messages, 'subtitle'),
      button: this.$tx(this.item.data.messages, 'button'),
      buttonLink: this.$tx(this.item.data.messages, 'buttonLink'),
    }
  }

  borderRadius = {
    medium: 'rounded-md',
    none: '',
    full: 'rounded-3xl',
  };
}


import { IFormInputConsent } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import { TranslationHandler } from '../../mixins/translation-handler'

@Component({})
export default class InputConsent extends Mixins(TranslationHandler) {
  @Prop() input!: IFormInputConsent;
  @Prop() isAlignmentCenter!: boolean;

  get translations (): IFormInputConsent['data']['messages'][0] {
    return {
      label: this.$tx(this.input.data.messages, 'label'),
      text: this.$tx(this.input.data.messages, 'text'),
    }
  }
}

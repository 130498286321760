
import { ISection } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import { TranslationHandler } from '../../mixins/translation-handler'

@Component({})
export default class Section extends Mixins(TranslationHandler) {
  @Prop() section!: ISection;

  get translations (): ISection['messages'][0] {
    return {
      title: this.$tx(this.section.messages, 'title'),
      subtitle: this.$tx(this.section.messages, 'subtitle'),
      button: this.$tx(this.section.messages, 'button'),
      buttonLink: this.$tx(this.section.messages, 'buttonLink'),
    }
  }

  borderRadius = {
    medium: 'rounded-lg',
    none: '',
    full: 'rounded-3xl',
  };
}

import Vue from 'vue'
import packageInfo from '../../package.json'
import App from './App.vue'
import '../registerServiceWorker'
import router from '../router'
import store from '../store'

import './components'

import './style/index.css'

console.info(`UMG Newsletter pages version: ${packageInfo.version}`)

Vue.config.productionTip = false

const create = (obj: Record<string, unknown>): Vue => new Vue(obj)

create({
  router,
  el: '#app',
  components: { App },
  store,
})


import { ISectionFeature } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import cms from '../../cms'
import { TranslationHandler } from '../../mixins/translation-handler'

@Component({
})
export default class Feature extends Mixins(TranslationHandler) {
  @Prop() item!: ISectionFeature

  get translations (): ISectionFeature['data']['messages'][0] {
    return {
      title: this.$tx(this.item.data.messages, 'title'),
    }
  }

  borderRadius = {
    medium: 'rounded-md',
    none: '',
    full: 'rounded-3xl'
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ThemeState } from '../../store/modules/theme'

@Component({})
export default class Button extends Vue {
  @Prop() title!: string;
  @Prop() background!: string;
  @Prop() rounded!: 'none' | 'full' | 'medium';
  @Prop() isFullWidth!: boolean;
  @Prop() icon!: 'whatsapp' | 'spotify';
  @Prop() iconColor!: string;
  @Prop() isSecondary!: boolean;
  @Prop() link!: string;
  @Prop() presetTextColor!: string;

  @Getter themeButtonText!: ThemeState['buttonText'];

  borderRadius = {
    medium: 'rounded-md',
    none: '',
    full: 'rounded-full',
  };

  get color () {
    if (this.presetTextColor) {
      return this.presetTextColor
    }

    if (this.isSecondary) {
      return '#000'
    }

    return this.themeButtonText
  }
}


import { ISectionForm, IUser } from '../../types'
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { TranslationHandler } from '../../mixins/translation-handler'
import cms from '@/cms'
import { DEFAULT_COUNTRY } from '../../data/default'

const EMAIL_PATTERN = /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/

@Component({
})
export default class Form extends Mixins(TranslationHandler) {
  @Prop() item!: ISectionForm
  @Prop() isBackgroundShown!: boolean

  @Getter formIsCompetitionSubmitted!: boolean
  @Getter formIsNewsletterSubmitted!: boolean

  @Getter campaignId!: string

  @Getter userCurrent!: IUser | null

  @Action('SUBMIT_COMPETITION_FORM') submitFormCompetition!: (data: { fields: Record<string, string | boolean> }) => Promise<void>
  @Action('SUBMIT_NEWSLETTER_FORM') submitFormNewsletter!: (data: { email: string, country: string, name: string }) => Promise<void>

  @Mutation('SET_USER_CURRENT') setUserCurrent!: (val: IUser) => void
  @Mutation('SET_FORM_IS_COMPETITION_SUBMITTED') setFormIsCompetitionSubmitted!: (val: boolean) => void
  @Mutation('SET_FORM_IS_NEWSLETTER_SUBMITTED') setForIsmNewsletterSubmitted!: (val: boolean) => void

  inputValues: (string | boolean)[] = []
  isErrorShown = false
  isSubmitting = false
  newsletterSuccess = true

  get translations (): ISectionForm['data']['messages'][0] {
    return {
      title: this.$tx(this.item.data.messages, 'title'),
      subtitle: this.$tx(this.item.data.messages, 'subtitle'),
      spotifyButtonText: this.$tx(this.item.data.messages, 'spotifyButtonText'),
      spotifyUnderButtonText: this.$tx(this.item.data.messages, 'spotifyUnderButtonText'),
      smallText: this.$tx(this.item.data.messages, 'smallText'),
      bottomText: this.$tx(this.item.data.messages, 'bottomText'),
      buttonText: this.$tx(this.item.data.messages, 'buttonText'),
      endText: this.$tx(this.item.data.messages, 'endText'),
      secondaryButtonText: this.$tx(this.item.data.messages, 'secondaryButtonText'),
    }
  }

  created () {
    this.inputValues = this.item.data.inputs.map((input) => {
      // value is a boolean for checkbox
      if (input.type === 'input-checkbox') {
        return false
      }

      // prefil email from Spotify
      if (input.type === 'input-text' && input.data.type === 'email' && this.userCurrent?.email) {
        return this.userCurrent.email
      }

      // prefil name from Spotify
      if (input.data.id === 'name' && this.userCurrent?.displayName) {
        return this.userCurrent.displayName
      }

      // prefil country from Spotify
      if (input.type === 'input-select' && input.data.type === 'country') {
        return this.userCurrent?.country || this.$userCountryCode || DEFAULT_COUNTRY
      }

      // consent input is sending the consent text for legal reasons
      if (input.type === 'input-consent') {
        return this.$tx(input.data.messages, 'text')
      }

      return ''
    })

    this.initNewsletterState()
  }

  initNewsletterState () {
    const state = new URLSearchParams(window.location.search).get(
      'newsletter_state'
    )

    if (state === 'success') {
      this.newsletterSuccess = false
    }
  }

  borderRadius = {
    medium: 'rounded-md',
    none: '',
    full: 'rounded-3xl'
  }

  onSpotifyLogin () {
    cms.tracking.trackCustomer('Registration', 'Start', 'customer_registration_start', {
      method: 'Spotify'
    })

    const url = `/api/campaigns/${this.campaignId}/auth/spotify/login?redirect_uri=${encodeURIComponent(window.location.href)}`
    window.open(url, '_self')
  }

  onSkip () {
    switch (this.item.data.formType) {
      case 'combined': {
        this.setForIsmNewsletterSubmitted(true)
        this.setFormIsCompetitionSubmitted(true)
        break
      }
      case 'newsletter': {
        this.setForIsmNewsletterSubmitted(true)
        break
      }
      case 'competition': {
        this.setFormIsCompetitionSubmitted(true)
        break
      }
    }
  }

  getIsInputValid (input: ISectionForm['data']['inputs'][0], value: boolean | string): boolean {
    if (!input.data.required) {
      return true
    }

    switch (input.type) {
      case 'input-text': {
        switch (input.data.type) {
          case 'email': {
            return EMAIL_PATTERN.test(value.toString())
          }
          default: {
            return value.toString().length > 0
          }
        }
      }
      case 'input-select': {
        return value.toString().length > 0
      }
      case 'input-radio': {
        return value.toString().length > 0
      }
      case 'input-checkbox': {
        return !!value
      }
      default: {
        return false
      }
    }
  }

  get formValuesById (): Record<string, any> {
    return Object.fromEntries(this.item.data.inputs.map((input, index) => {
      return [input.data.id, this.inputValues[index]]
    }))
  }

  get isFormValid (): boolean {
    return this.inputValues
      .map((inputValue, index) => this.getIsInputValid(this.item.data.inputs[index], inputValue))
      .every(v => !!v)
  }

  get inputCountryId () {
    return this.item.data.inputs.find((input) => input.type === 'input-select' && input.data.type === 'country')?.data.id
  }

  setUser () {
    this.setUserCurrent({
      campaignId: 0,
      country: this.formValuesById[this.inputCountryId || ''] || '',
      displayName: this.formValuesById.name || '',
      email: this.formValuesById.email || '',
      id: 0,
      profilePicture: '',
      service: '',
      serviceId: '',
    })
  }

  async onSubmitCompetition () {
    try {
      this.setUser()
      await this.submitFormCompetition({
        fields: this.formValuesById,
      })
      cms.tracking.trackGame('Competition', 'Subscribe - Start', 'competition_subscribe_start')

      document.body.classList.remove('overflow-hidden')
    } catch (e) {
      cms.tracking.trackGame('Competition', 'Subscribe - Successful', 'competition_subscribe_success', {
        error_message: (e as string),
      })
      throw (e)
    }
  }

  async onSubmitNewsletter () {
    this.setUser()
    await this.submitFormNewsletter({
      country: this.formValuesById[this.inputCountryId || ''] || '',
      name: this.formValuesById.name || '',
      email: this.formValuesById.email || '',
    })
    document.body.classList.remove('overflow-hidden')
  }

  async onSubmit () {
    if (this.isSubmitting) {
      return
    }

    this.isErrorShown = false

    if (this.isFormValid === false) {
      this.isErrorShown = true
      return
    }

    this.isSubmitting = true
    try {
      switch (this.item.data.formType) {
        case 'combined': {
          await this.onSubmitCompetition()
          await this.onSubmitNewsletter()
          break
        }
        case 'newsletter': {
          await this.onSubmitNewsletter()
          break
        }
        case 'competition': {
          await this.onSubmitCompetition()
          break
        }
      }
    } catch (e) {
      console.error(e)
      window.alert(e)
    } finally {
      this.isSubmitting = false
    }
  }
}


import { TranslationHandler } from '@/mixins/translation-handler'
import { IFooter } from '@/types'
import { Mixins, Component, Prop } from 'vue-property-decorator'

@Component({
})
export default class Footer extends Mixins(TranslationHandler) {
  @Prop() data!: IFooter
}


import { IFormInputSelect } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import { TranslationHandler } from '../../mixins/translation-handler'
import cms from '../../cms'

@Component({
})
export default class InputSelect extends Mixins(TranslationHandler) {
  @Prop() input!: IFormInputSelect
  @Prop() value!: string
  @Prop() isErrorShown!: boolean

  get translations (): IFormInputSelect['data']['messages'][0] {
    return {
      label: this.$tx(this.input.data.messages, 'label'),
      error: this.$tx(this.input.data.messages, 'error'),
      placeholder: this.$tx(this.input.data.messages, 'placeholder'),
    }
  }

  get options (): [string, string][] {
    if (this.input.data.type === 'country') {
      return Object.entries(cms.i18n.currentLocaleCountries)
    }
    return this.input.data.items.map(item => [item.id, item.messages[this.$tLocaleCurrent].label])
  }

  get _value (): string {
    return this.value
  }

  set _value (data: string) {
    this.$emit('input', data)
  }

  get cssVars () {
    return {
      '--bg-color': this.input.data.background,
      '--text-color': this.input.data.textColor
    }
  }

  borderRadius = {
    medium: 'rounded-md',
    none: '',
    full: 'rounded-3xl'
  }
}

import Vue from 'vue'

import Modal from './components/Modal.vue'
import Intro from './components/Intro.vue'
import Button from './components/Button.vue'
import Card from './components/Card.vue'
import Feature from './components/Feature.vue'
import Form from './components/Form.vue'
import Icon from './components/Icon.vue'
import InputCheckbox from './components/InputCheckbox.vue'
import InputConsent from './components/InputConsent.vue'
import InputRadio from './components/InputRadio.vue'
import InputSelect from './components/InputSelect.vue'
import InputText from './components/InputText.vue'
import Media from './components/Media.vue'
import Footer from './components/Footer.vue'
import Section from './components/Section.vue'

Vue.component('Modal', Modal)
Vue.component('Intro', Intro)
Vue.component('Button', Button)
Vue.component('Card', Card)
Vue.component('Feature', Feature)
Vue.component('Form', Form)
Vue.component('Icon', Icon)
Vue.component('InputCheckbox', InputCheckbox)
Vue.component('InputConsent', InputConsent)
Vue.component('InputRadio', InputRadio)
Vue.component('InputSelect', InputSelect)
Vue.component('InputText', InputText)
Vue.component('Media', Media)
Vue.component('Footer', Footer)
Vue.component('Section', Section)

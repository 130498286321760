
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
})
export default class Modal extends Vue {
  @Prop() isOpen!: boolean

  @Getter themeRounded!: 'medium' | 'full' | 'none'
  @Getter themeBackgroundColor!: string

  borderRadius = {
    medium: 'md:rounded-md',
    none: '',
    full: 'md:rounded-3xl'
  }

  @Watch('isOpen')
  watchModalOpen (isOpen: boolean) {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }

  closeModal () {
    this.$emit('close')
  }
}


import { IFormInputText } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import { TranslationHandler } from '../../mixins/translation-handler'
import intlTelInput from 'intl-tel-input'
import cms from '@/cms'

@Component({
})
export default class InputText extends Mixins(TranslationHandler) {
  @Prop() input!: IFormInputText
  @Prop() value!: string
  @Prop() isErrorShown!: boolean

  $refs!: {
    tel: HTMLInputElement;
  }

  intlTelInput: any

  mounted () {
    // if type is tel, initialize intlTelInput
    if (this.input.data.type === 'telephone') {
      this.intlTelInput = intlTelInput(this.$refs.tel, {
        initialCountry: this.input.data?.preSelect?.toLocaleLowerCase() || cms.i18n.userCountryCode?.toLocaleLowerCase() || 'de',
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js',
      })

      // listen to 'keyup', but also 'change' to update when the user selects a country
      this.$refs.tel.addEventListener('change', this.handleTelChange)
      this.$refs.tel.addEventListener('keyup', this.handleTelChange)
    }
  }

  handleTelChange () {
    this._value = this.intlTelInput.getNumber()
  }

  get translations (): IFormInputText['data']['messages'][0] {
    return {
      label: this.$tx(this.input.data.messages, 'label'),
      error: this.$tx(this.input.data.messages, 'error'),
      placeholder: this.$tx(this.input.data.messages, 'placeholder'),
    }
  }

  get _value (): string {
    return this.value
  }

  set _value (data: string) {
    this.$emit('input', data)
  }

  get cssVars () {
    return {
      '--bg-color': this.input.data.background,
      '--text-color': this.input.data.textColor
    }
  }

  borderRadius = {
    medium: 'rounded-md',
    none: '',
    full: 'rounded-3xl'
  }
}

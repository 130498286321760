
import { IFormInputRadio } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import cms from '../../cms'
import { TranslationHandler } from '../../mixins/translation-handler'

@Component({})
export default class InputRadio extends Mixins(TranslationHandler) {
  @Prop() input!: IFormInputRadio
  @Prop() value!: string
  @Prop() isErrorShown!: boolean

  get translations (): IFormInputRadio['data']['messages'][0] {
    return {
      label: this.$tx(this.input.data.messages, 'label'),
      error: this.$tx(this.input.data.messages, 'error'),
    }
  }

  get _value (): string {
    return this.value
  }

  set _value (data: string) {
    this.$emit('input', data)
  }

  get cssVars () {
    return {
      '--bg-color': this.input.data.background,
      '--checked-color': this.input.data.checkedColor,
    }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"max-w-7xl mx-auto"},[_c('div',{staticClass:"w-full relative flex flex-col px-4 md:px-8",class:_vm.section.isAlignmentCenter ? 'items-center' : 'items-start'},[(
        _vm.translations.button || _vm.translations.title || _vm.translations.subtitle
      )?_c('div',{staticClass:"mb-20",class:_vm.section.isAlignmentCenter ? ' text-center' : 'text-left'},[(_vm.translations.title)?_c('h2',{staticClass:"font-black text-3xl lg:text-5xl mb-7"},[_vm._v(" "+_vm._s(_vm.translations.title)+" ")]):_vm._e(),(_vm.translations.subtitle)?_c('p',{staticClass:"text-md lg:text-xl max-w-2xl",class:[{ 'mx-auto': _vm.section.isAlignmentCenter }],domProps:{"innerHTML":_vm._s(_vm.translations.subtitle)}}):_vm._e(),(_vm.translations.button)?_c('Button',{staticClass:"mt-7",attrs:{"title":_vm.translations.button,"link":_vm.translations.buttonLink,"background":_vm.section.buttonBackground,"rounded":_vm.section.rounded,"isFullWidth":false}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"relative z-10 grid gap-8 md:gap-28 items-center",class:[
        _vm.section.cols === 1 ? 'md:grid-cols-1' : '',
        _vm.section.cols === 2 ? 'md:grid-cols-2' : '',
        _vm.section.cols === 3 ? 'md:grid-cols-3' : '',
        _vm.section.cols === 4 ? 'md:grid-cols-4' : '',
        _vm.section.isAlignmentCenter ? 'justify-items-center' : '',
        _vm.section.isFullWidth ? 'w-full' : '',
      ]},_vm._l((_vm.section.items),function(item,index){return _c('div',{key:index,staticClass:"w-full"},[(item.type === 'card')?_c('Card',{attrs:{"item":item}}):_vm._e(),(item.type === 'form')?_c('Form',{attrs:{"item":item}}):_vm._e(),(item.type === 'media')?_c('Media',{attrs:{"item":item}}):_vm._e(),(item.type === 'feature')?_c('Feature',{attrs:{"item":item}}):_vm._e()],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-28 items-center",class:[
    _vm.item.data.background ? 'p-4 md:p-8' : '',
    _vm.borderRadius[_vm.item.data.rounded],
  ],style:({ background: _vm.item.data.background })},[(_vm.item.data.imageUrl)?_c('figure',{staticClass:"overflow-hidden w-full flex",class:[
      _vm.borderRadius[_vm.item.data.rounded],
      _vm.item.data.isImageFirstDesktop ? 'md:order-first' : 'md:order-last',
    ]},[_c('img',{staticClass:"w-full",attrs:{"src":_vm.item.data.imageUrl}})]):_vm._e(),(_vm.translations.button || _vm.translations.title || _vm.translations.subtitle)?_c('div',{staticClass:"mb-8 md:mb-0"},[_c('h2',{staticClass:"font-black text-3xl xl:text-5xl mb-7"},[_vm._v(" "+_vm._s(_vm.translations.title)+" ")]),_c('p',{staticClass:"text-md xl:text-xl mb-7 max-w-xl",domProps:{"innerHTML":_vm._s(_vm.translations.subtitle)}}),(_vm.translations.button)?_c('Button',{attrs:{"title":_vm.translations.button,"link":_vm.translations.buttonLink,"background":_vm.item.data.buttonBackground,"rounded":_vm.item.data.rounded,"isFullWidth":false}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { IIntro } from '../../types'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
})

export default class Intro extends Vue {
  @Prop() data!: IIntro
}


import { IFormInputCheckbox } from '../../types'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import { TranslationHandler } from '../../mixins/translation-handler'

@Component({})
export default class InputCheckbox extends Mixins(TranslationHandler) {
  @Prop() input!: IFormInputCheckbox;
  @Prop() value!: boolean;
  @Prop() isErrorShown!: boolean;

  get translations (): IFormInputCheckbox['data']['messages'][0] {
    return {
      overline: this.$tx(this.input.data.messages, 'overline'),
      label: this.$tx(this.input.data.messages, 'label'),
      error: this.$tx(this.input.data.messages, 'error'),
    }
  }

  get _value (): boolean {
    return this.value
  }

  set _value (data: boolean) {
    this.$emit('input', data)
  }

  get cssVars () {
    return {
      '--bg-color': this.input.data.background,
      '--checked-color': this.input.data.checkedColor,
    }
  }

  borderRadius = {
    medium: 'rounded-md',
    none: '',
    full: 'rounded-md',
  };
}
